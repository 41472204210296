import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Icon from "./Icon";
import icons from "../assets/icons";
import Modal from "./Modal";
import TicketCounter from "./TicketCounter";
import ButtonLink from "./ButtonLink";
import { useNavigate } from "react-router-dom";
import soundService from "../services/soundService";

const Header = observer(() => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [scoreAnimation, setScoreAnimation] = useState({ show: false, amount: 0 });
  const [isSoundEnabled, setIsSoundEnabled] = useState(soundService.isSoundEnabled);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (gameStore.currentGame.isStarted) {
      timer = setInterval(() => {
        gameStore.updateGameTime();
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [gameStore.currentGame.isStarted]);

  // Эффект для анимации изменения очков
  useEffect(() => {
    const { amount, timestamp } = gameStore.currentGame.lastScoreChange;
    if (amount !== 0 && timestamp > 0) {
      setScoreAnimation({ show: true, amount });
      const timer = setTimeout(() => {
        setScoreAnimation({ show: false, amount: 0 });
      }, 1000); // Анимация длится 1 секунду
      return () => clearTimeout(timer);
    }
  }, [gameStore.currentGame.lastScoreChange]);

  const handleSettingsToggle = (isOpen) => {
    setIsSettingsOpen(isOpen);
  };

  const handleNewGame = () => {
    if (gameStore.tickets > 0) {
      gameStore.startNewGame();
      handleSettingsToggle(false);
    }
  };

  const handleExitToMenu = () => {
    navigate("/");
  };

  const handleSoundToggle = () => {
    const isEnabled = soundService.toggleSound();
    setIsSoundEnabled(isEnabled);
    if (isEnabled) {
      soundService.playClick();
    }
  };

  return (
    <>
      <div
        className="fixed top-0 left-0 right-0 h-12 bg-[#623FA57D] flex items-center justify-between px-3 z-50"
        style={{
          border: "1px solid #623FA5",
        }}
      >
        {/* Очки */}
        <div className="text-[#F6F3FC] flex flex-col items-left relative">
          <span className="text-[10px] text-[#FFFFFFBD]">ОЧКИ:</span>
          <span className="font-bold text-xl leading-none">{gameStore.currentGame.score}</span>
          {/* Анимация изменения очков */}
          {scoreAnimation.show && (
            <div 
              className={`absolute -right-3 -top-1 font-bold text-base transition-all duration-1000 ${
                scoreAnimation.amount > 0 ? 'text-green-400' : 'text-red-400'
              }`}
              style={{
                animation: 'fadeUpAndOut 1s ease-out forwards'
              }}
            >
              {scoreAnimation.amount > 0 ? '+' : ''}{scoreAnimation.amount}
            </div>
          )}
        </div>

        {/* Время */}
        <div className="text-[#F6F3FC] font-bold text-xl">
          {gameStore.formattedTime}
        </div>

        {/* Ходы и настройки */}
        <div className="flex items-center">
          <div className="text-[#F6F3FC] flex flex-col items-right mr-3">
            <span className="text-[10px] text-[#FFFFFFBD]">ХОДЫ:</span>
            <span className="font-bold text-xl leading-none text-right">{gameStore.currentGame.moves}</span>
          </div>
          <div className="w-px h-6 bg-[#F6F3FC] mr-2 opacity-20"></div>
          <button 
            onClick={() => handleSettingsToggle(true)}
            className="text-[#F6F3FC] bg-[#F6F3FC] w-8 h-8 rounded-lg flex items-center justify-center"
            style={{
              boxShadow: "0px 2px 0px 0px #623FA5",
            }}
          >
            <Icon
              icon={icons.Settings}
              className="w-8 h-8 fill-[#623FA5]"
            />
          </button>
        </div>

        {/* Модальное окно настроек */}
        <Modal
          isOpen={isSettingsOpen}
          onClose={() => handleSettingsToggle(false)}
          title="Настройки"
        >
          <div className="p-3 pt-0 flex flex-col gap-3">
            <ButtonLink
              to="/"
              text="Выйти в меню"
              className="bg-[#F6F3FC] text-[#6641A9] py-1.5 mx-2.5"
              style={{
                boxShadow: "0px 4px 0px 0px #4F3285",
              }}
              onClick={handleExitToMenu}
            />
            
            {gameStore.tickets > 0 ? (
              <ButtonLink
                icon={icons.Ticket}
                onClick={handleNewGame}
                text="Новая игра"
                afterText="x1"
                className="bg-[#F6F3FC] text-[#6641A9] py-1.5 mx-9"
                iconClassName="fill-[#6641A9]"
                style={{
                  boxShadow: "0px 4px 0px 0px #623FA5",
                }}
              />
            ) : (
              <ButtonLink
                icon={icons.Ticket}
                text="Недостаточно"
                to="/shop"
                className="bg-[#F335B4] text-[#F6F3FC] py-1.5 mx-6"
                iconClassName="fill-[#F6F3FC]"
                style={{
                  boxShadow: "0px 4px 0px 0px #AF0C79",
                }}
                disabled
              />
            )}
            <div className="flex flex-col items-center">
              <p
                className="text-[#F6F3FC] font-bold text-lg text-center mb-2 uppercase"
                style={{ textShadow: "0px 4px 0px #623FA5" }}
              >Настройки звука:</p>
              <button
                onClick={handleSoundToggle}
                className={`${isSoundEnabled ? 'bg-[#C9F456] text-[#247A0A]' : 'bg-[#F6F3FC] text-[#6641A9]'} hover:bg-gray-100 py-1.5 px-3 rounded-lg flex items-center justify-center`}
                style={{
                  boxShadow: isSoundEnabled ? "0px 4px 0px 0px #247A0A" : "0px 4px 0px 0px #623FA5",
                }}
              >
                <Icon
                  icon={isSoundEnabled ? icons.SoundOn : icons.SoundOff}
                  className={`w-5 h-5`}
                />
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
});

export default Header;
