import { initializeApp } from 'firebase/app';
import {
    getAnalytics,
    logEvent,
    setAnalyticsCollectionEnabled,
    isSupported
} from 'firebase/analytics';

const firebaseConfig = {
    apiKey: "AIzaSyA2UmIA612Oli11xGRWYj2haHTUP5Hb0xs",
    authDomain: "jam-style-efb57.firebaseapp.com",
    projectId: "jam-style-efb57",
    storageBucket: "jam-style-efb57.firebasestorage.app",
    messagingSenderId: "577219321738",
    appId: "1:577219321738:web:1beb6c92bfce45f446f273",
    measurementId: "G-WVM4XM3ZNS"
};

class AnalyticsService {
    constructor() {
        this.sessionStartTime = parseInt(localStorage.getItem('solitaire_session_start')) || null;
        this.analytics = null;
        this.initialized = false;
        this.eventQueue = [];
        this.initializeAnalytics();

        if (window.Telegram?.WebApp) {
            // Включаем BackButton
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(this.handleTelegramBack.bind(this));

            // Обработка событий приложения
            window.Telegram.WebApp.onEvent('viewportChanged', this.handleViewportChanged.bind(this));
        }
    }

    async initializeAnalytics() {
        try {
            const analyticsSupported = await isSupported();
            if (!analyticsSupported) {
                return;
            }

            const app = initializeApp(firebaseConfig);
            this.analytics = getAnalytics(app);
            await setAnalyticsCollectionEnabled(this.analytics, true);

            this.initialized = true;

            if (this.eventQueue.length > 0) {
                for (const { eventName, params } of this.eventQueue) {
                    await this.sendEvent(eventName, params);
                }
                this.eventQueue = [];
            }
        } catch (error) {
            console.error('AnalyticsService: Failed to initialize Firebase Analytics:', error);
        }
    }

    get userId() {
        const id = window.Telegram?.WebApp?.initDataUnsafe?.user?.id;
        return id || 'anonymous';
    }

    // Начало игровой сессии
    async startSession() {
        this.sessionStartTime = Date.now();
        localStorage.setItem('solitaire_session_start', this.sessionStartTime.toString());

        const params = {
            user_id: this.userId,
            start_time: this.sessionStartTime
        };
        await this.trackEvent('session_start', params);
    }

    // Начало новой игры
    async logGameStart() {
        const params = {
            user_id: this.userId,
            timestamp: Date.now()
        };
        await this.trackEvent('game_start', params);
    }

    // Результат игры (победа)
    async logGameResult(result,score) {
        const params = {
            user_id: this.userId,
            result,
            score
        };
        await this.trackEvent('game_result', params);
    }

    // Перезагрузка игры
    async logReload() {
        const params = {
            user_id: this.userId,
            timestamp: Date.now()
        };
        await this.trackEvent('game_reload', params);
    }

    // Обработчик изменения видимости страницы
    handleVisibilityChange() {
        if (document.visibilityState === 'hidden') {
            this.logExit(true);
        }
    }

    // Обработчик события закрытия браузера
    handleBeforeUnload(event) {
        this.logExit(true);
        event.preventDefault();
        event.returnValue = '';
    }

    // Обработчик изменения viewport в Telegram WebApp
    handleViewportChanged(event) {
        if (event.isStateStable === false) {
            this.logExit();
        }
    }

    // Обработчик нажатия кнопки "Назад" в Telegram
    handleTelegramBack() {
        this.logExit(true);
        // Задержка перед закрытием
        setTimeout(() => {
            window.Telegram.WebApp.close();
        }, 150);
    }

    // Выход из игры
    logExit(useBeacon = false) {
        if (!this.sessionStartTime || !this.analytics) {
            return;
        }

        const duration = this.getSessionDuration();
        const params = {
            user_id: this.userId,
            session_duration: duration,
            timestamp: Date.now()
        };

        try {
            if (useBeacon && navigator.sendBeacon) {
                // Используем sendBeacon для гарантированной отправки
                const measurementId = firebaseConfig.measurementId;
                const analyticsEndpoint = `https://www.google-analytics.com/g/collect?measurement_id=${measurementId}`;

                const data = {
                    client_id: this.userId,
                    events: [{
                        name: 'session_exit',
                        params: {
                            ...params,
                            event_time: Date.now(),
                            platform: 'telegram_web_app'
                        }
                    }]
                };

                navigator.sendBeacon(analyticsEndpoint, JSON.stringify(data));
            }

            // Также пытаемся отправить через Firebase
            logEvent(this.analytics, 'session_exit', {
                ...params,
                event_time: Date.now(),
                platform: 'telegram_web_app'
            });

            this.sessionStartTime = null;
            localStorage.removeItem('solitaire_session_start');
        } catch (error) {
            console.error('AnalyticsService: Failed to log exit event:', error);
        }
    }

    getSessionDuration() {
        if (!this.sessionStartTime) return 0;
        const duration = Math.floor((Date.now() - this.sessionStartTime) / 1000);
        return duration;
    }

    async trackEvent(eventName, params = {}) {
        if (!this.initialized) {
            this.eventQueue.push({ eventName, params });
            return;
        }
        await this.sendEvent(eventName, params);
    }

    async sendEvent(eventName, params = {}) {
        try {
            if (!this.analytics) return;

            // Добавляем общие параметры
            const eventParams = {
                ...params,
                event_time: Date.now(),
                platform: 'telegram_web_app'
            };

            await logEvent(this.analytics, eventName, eventParams);
            console.log(`AnalyticsService: Event sent - ${eventName}`, eventParams);
        } catch (error) {
            console.error(`AnalyticsService: Failed to log event '${eventName}':`, error);
        }
    }
}

const analyticsService = new AnalyticsService();
export default analyticsService;
