import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./features/MainApp";
import { AuthProvider } from "./components/auth/AuthContext";
import gameStore from "./store/store";
import Alert from "./components/Alert";
import analyticsService from './services/analyticsService';

const App = () => {
  useEffect(() => {
    let isReloading = false;

    // Инициализация Telegram WebApp
    if (window.Telegram && window.Telegram.WebApp) {
      const tg = window.Telegram.WebApp;
      tg.ready();
      tg.expand();
    }

    // Обработчик перед перезагрузкой/закрытием страницы
    const handleBeforeUnload = async (event) => {
      // Определяем, перезагрузка это или закрытие
      const navigationType = window.performance
        .getEntriesByType('navigation')
        .map((nav) => nav.type)[0];

      if (navigationType === 'reload' && !isReloading) {
        isReloading = true;
        await analyticsService.logReload();
        // Даем время на отправку события
        await new Promise(resolve => setTimeout(resolve, 300));
      }
    };

    // Добавляем слушатель
    window.addEventListener('beforeunload', handleBeforeUnload);

    // При первой загрузке
    const navigationType = window.performance
      .getEntriesByType('navigation')
      .map((nav) => nav.type)[0];
    
    if (navigationType === 'reload') {
      analyticsService.logReload();
    } else {
      analyticsService.startSession();
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Alert />
        <MainApp />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
