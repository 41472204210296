import axios from "axios";
import { makeAutoObservable, computed } from "mobx";
import { API_BASE_URL, API_ENDPOINTS } from '../config'
import soundService from '../services/soundService';
import UpdateService from '../services/updateService';
import analyticsService from '../services/analyticsService';

// Константы для системы очков
const GAME_SCORE_CONSTANTS = {
  INITIAL_SCORE: 0,    // Начальное количество очков
  GREEN_LETTER_POINTS: 10,  // Очки за зеленую букву
  YELLOW_LETTER_POINTS: 10,  // Очки за желтую букву
  WORD_GUESS_BONUS: 100,        // Очки за отгадывание слова
  UNUSED_ATTEMPTS_BONUS: 50, // Очки за неиспользованные попытки
};

class GameStore {
  tickets = 5; // Начальное значение для тестирования
  myScore = 10;
  myPosition = "???";
  token = "";
  myName = "";
  gameTime = 0;
  history = [];
  currentGame = {
    isStarted: false,
    guesses: [],
    currentGuess: '',
    currentRow: 0,
    invalidGuess: false,
    word: '',
    gameOver: false,
    letterStates: {},
    lastScoreChange: { amount: 0, timestamp: Date.now() },
    score: GAME_SCORE_CONSTANTS.INITIAL_SCORE,
    moves: 0
  };
  loginStatus = false;
  dataUnsafe = {};
  error = null;
  friends = [];
  leaderboard = [];
  isNewProfile = false;

  alert = {
    show: false,
    content: null
  };

  constructor() {
    makeAutoObservable(this, {
      formattedTime: computed,
      isVictory: computed,
    }, {
      enforceActions: "never"
    });
    new UpdateService(this)
    // Загружаем состояние игры, время и историю
    const savedState = localStorage.getItem('gameState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      this.currentGame = parsedState.currentGame;
      this.gameTime = parsedState.gameTime;
      this.history = parsedState.history;
    }
  }

  checkGuess(guess) {
    if (guess.length !== 5) {
      this.currentGame.invalidGuess = true;
      soundService.playSound('defeat'); // Звук для неверной длины слова
      return;
    }
    this.currentGame.invalidGuess = false;
    this.currentGame.guesses.push({ word: guess, letterStates: this.evaluateGuess(guess) });
    this.currentGame.currentRow++;
    if (guess === this.currentGame.word) {
      this.currentGame.gameOver = true;
      soundService.playSound('victory'); // Звук победы
    } else if (this.currentGame.currentRow >= 6) {
      this.currentGame.gameOver = true;
      soundService.playSound('defeat'); // Звук поражения
    } else {
      soundService.playClick(); // Звук для ввода слова
    }
  }

  evaluateGuess(guess) {
    const result = [];
    for (let i = 0; i < guess.length; i++) {
      if (guess[i] === this.currentGame.word[i]) {
        result.push('correct');
      } else if (this.currentGame.word.includes(guess[i])) {
        result.push('present');
      } else {
        result.push('absent');
      }
    }
    return result;
  }

  get formattedTime() {
    const minutes = Math.floor(this.gameTime / 60);
    const seconds = this.gameTime % 60;
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
  updateGameTime = () => {
    this.gameTime += 1;
  };

  updateToken(token) {
    this.token = token
  }

  async startNewGame() {
    if (this.tickets > 0) {
      this.tickets--;
      // Сбрасываем состояние только при явном старте новой игры
      this.clearGameState();
        this.currentGame = {
          isStarted: true,
          guesses: [],
          currentGuess: '',
          currentRow: 0,
          invalidGuess: false,
          word: '',
          gameOver: false,
          letterStates: {},
          lastScoreChange: { amount: 0, timestamp: Date.now() },
          score: GAME_SCORE_CONSTANTS.INITIAL_SCORE,
          moves: 0
        };
      this.initializeGame();
      this.gameTime = 0;
      this.saveGameState();
      soundService.playNewGame();
      analyticsService.logGameStart();
    }
  };

  addTickets(amount) {
    this.tickets += amount;
  }

  updateOpponent(name, tickets) {
    this.opponent = { name, tickets };
  }

  updateStatus(status) {
    this.loginStatus = status
    if (!status) {
      this.setError("Не удалось авторизоваться");
    }
  }

  setError(message) {
    this.error = message;
  }

  clearError() {
    this.error = null;
  }

  updateDataUnsafe(status) {
    this.dataUnsafe = status;
    if (status?.user) {
      this.myName = status.user.first_name || status.user.last_name || status.user.username || status.user.id?.toString() || "Player";
    }
  }

  // Обновление очков с анимацией
  updateScore(amount) {
    const newScore = Math.max(0, this.currentGame.score + amount);
    this.currentGame.lastScoreChange = {
      amount: amount,
      timestamp: Date.now()
    };
    this.currentGame.score = newScore;
  }


  // Метод для инициализации новой игры
  initializeGame() {
    axios.post(`${API_BASE_URL}${API_ENDPOINTS.START}`, {}, {
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    })
      .then(response => {
        const data = response.data;
        if (response.status === 200) {
          this.clearError();

          const decodedWord = JSON.parse(`"${data.playground.word}"`);
          this.currentGame.word = decodedWord;
          this.currentGame.hash = data.hash;

        } else {
          this.setError("Не удалось загрузить игру");

        }
      })
      .catch(error => {
        console.error('Failed to fetch deck:', error);
        this.setError("Не удалось загрузить игру");

      });
  }


  // Update profile data from API response
  updateProfile = (profile) => {
    if (profile) {
      this.tickets = profile.tickets || 0;
      this.myScore = profile.score || 0;
      this.myName = profile.name || this.myName;
    }
  }

  // Reset game and clear saved state
  resetGame = () => {
    this.clearGameState();
    this.currentGame = {
      isStarted: false,
      guesses: [],
      currentGuess: '',
      currentRow: 0,
      invalidGuess: false,
      word: '',
      gameOver: false,
      letterStates: {},
      lastScoreChange: { amount: 0, timestamp: Date.now() },
      score: GAME_SCORE_CONSTANTS.INITIAL_SCORE,
      moves: 0
    };
    this.saveGameState();
  };

  // Send win data to server
  sendWinData = (guess) => {
    return axios.post(`${API_BASE_URL}${API_ENDPOINTS.WIN}`, {
      hash: this.currentGame.hash,
      scores: this.currentGame.score,
      answer: guess,
    }, {
      headers: {
        'Authorization': `Bearer ${this.token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
  }

  saveGameState = () => {
    const state = {
      currentGame: this.currentGame,
      gameTime: this.gameTime,
    };
    localStorage.setItem('gameState', JSON.stringify(state));
  };

  loadGameState = () => {
    const savedState = localStorage.getItem('gameState');
    if (savedState) {
      try {
        const gameState = JSON.parse(savedState);

        // Проверяем, есть ли активная игра
        if (gameState.currentGame && gameState.currentGame.isStarted && !gameState.currentGame.gameOver) {
          // Восстанавливаем состояние только если игра активна
          this.currentGame = gameState.currentGame;
          this.gameTime = gameState.gameTime;
          this.history = gameState.history;
        }
      } catch (error) {
        console.error('Ошибка при загрузке состояния игры:', error);
        // НЕ сбрасываем игру при ошибке загрузки
      }
    }
  };

  // Clear saved game state
  clearGameState = () => {
    localStorage.removeItem('gameState');
  };


  // Получение ссылки для приглашения друзей
  getInviteLink = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_BASE_URL}${API_ENDPOINTS.FRIEND_INVITE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.clearError();
          // Проверяем наличие данных
          if (!response.data) {
            reject(new Error('Нет данных в ответе'));
            return;
          }

          // Если ссылка сразу доступна
          if (response.data.link) {
            resolve(response.data.link);
            return;
          }

          // Если получили id, возвращаем его как ссылку
          if (response.data.id) {
            resolve(response.data.id);
            return;
          }

          reject(new Error('Не удалось получить ссылку'));
        })
        .catch(error => {
          this.setError("Что то пошло не так...");
          console.error('Error getting invite link:', error);
          reject(error);
        });
    });
  }

  getInviteLobbyLink = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_BASE_URL}${API_ENDPOINTS.GAME_INVITE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.clearError();
          // Проверяем наличие данных
          if (!response.data) {
            reject(new Error('Нет данных в ответе'));
            return;
          }

          // Если ссылка сразу доступна
          if (response.data.link) {
            resolve(response.data.link);
            return;
          }

          // Если получили id, возвращаем его как ссылку
          if (response.data.id) {
            resolve(response.data.id);
            return;
          }

          reject(new Error('Не удалось получить ссылку'));
        })
        .catch(error => {
          this.setError("Что то пошло не так...");
          console.error('Error getting invite link:', error);
          reject(error);
        });
    });
  }

  getInviteLobby = () => {
    return new Promise((resolve, reject) => {
      axios.get(`${API_BASE_URL}${API_ENDPOINTS.GAME_INVITE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
        .then(response => {
          this.clearError();
          // Проверяем наличие данных
          if (!response.data) {
            reject(new Error('Нет данных в ответе'));
            return;
          }

          // Если ссылка сразу доступна
          if (response.data.link) {
            resolve(response.data.link);
            return;
          }

          // Если получили id, возвращаем его как ссылку
          if (response.data.id) {
            resolve(response.data.id);
            return;
          }

          reject(new Error('Не удалось получить ссылку'));
        })
        .catch(error => {
          this.setError("Что то пошло не так...");
          console.error('Error getting invite link:', error);
          reject(error);
        });
    });
  }

  inviteLink = "";
  showInviteModal = false;

  inviteFriends = async (isLobby) => {
    try {
      const link = isLobby ? await this.getInviteLobby() : await this.getInviteLink();
      if (!link) {
        throw new Error("Ссылка отсутствует");
      }

      // Функция для копирования в буфер обмена
      const copyToClipboard = async (text) => {
        try {
          if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
            await navigator.clipboard.writeText(text);
            return true;
          } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            // Фолбэк для старых браузеров
            const textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Избегаем прокрутки страницы
            document.body.appendChild(textarea);
            textarea.select();
            try {
              document.execCommand("copy");
              return true;
            } catch (ex) {
              console.warn("Не удалось скопировать в буфер:", ex);
              return false;
            } finally {
              document.body.removeChild(textarea);
            }
          } else {
            return false;
          }
        } catch (err) {
          console.warn("Ошибка при копировании в буфер:", err);
          return false;
        }
      };

      // Попытка скопировать ссылку в буфер
      const copied = await copyToClipboard(link);
      if (copied) {
        // Уведомление пользователя об успешном копировании
        if (window.Telegram?.WebApp) {
          window.Telegram.WebApp.showPopup({
            title: "Успешно",
            message: "Ссылка для приглашения скопирована в буфер обмена",
            buttons: [{ type: "ok", text: "OK" }],
          });
        } else {
          alert("Ссылка для приглашения скопирована в буфер обмена!");
        }
        return; // Завершаем выполнение после успешного копирования
      }

      // Если копирование не удалось, пробуем открыть Telegram WebApp Inline Query
      if (window.Telegram?.WebApp) {
        try {
          await window.Telegram.WebApp.switchInlineQuery(link);
          return; // Успешное выполнение Inline Query
        } catch (inlineError) {
          console.warn("Ошибка switchInlineQuery:", inlineError);
        }
      }

      // Если Inline Query не сработало, пробуем использовать нативное "Поделиться"
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Приглашение в игру",
            text: `Присоединяйтесь к игре: ${link}`,
            url: link,
          });
          return; // Успешное выполнение share
        } catch (shareError) {
          console.warn("Ошибка при использовании 'Поделиться':", shareError);
        }
      }

      // Если все остальные способы не сработали, показываем модальное окно с ссылкой
      this.inviteLink = link;
      this.showInviteModal = true;

    } catch (error) {
      console.error("Ошибка при попытке пригласить друзей:", error);
      if (window.Telegram?.WebApp) {
        window.Telegram.WebApp.showPopup({
          title: "Ошибка",
          message: error.message || "Не удалось выполнить действие",
          buttons: [{ type: "ok", text: "OK" }],
        });
      } else {
        alert("Ошибка: " + (error.message || "Не удалось выполнить действие"));
      }
    }
  };

  // Обработка хэша приглашения
  handleInviteHash = async (hash) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.FRIEND_ACCESS}`,
        { hash },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      if (response.data?.profile) {
        this.clearError();
        this.updateProfile(response.data.profile);
        await this.fetchProfile();
      }

      return response.data;
    } catch (error) {
      this.setError("Что то пошло не так...");
      console.error('Error handling invite:', error);
      throw error;
    }
  }

  handleLobbyHash = async (hash) => {
    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.GAME_ACCESS}`,
        { hash },
        {
          headers: {
            'Authorization': `Bearer ${this.token}`,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          }
        }
      );

      if (response.data?.profile) {
        this.updateProfile(response.data.profile);
        await this.fetchProfile();
        // update cards
      }

      if (response.data?.lobby) {
        this.updateLobby(response.data.lobby);
      }
      this.clearError();
      return response.data;
    } catch (error) {
      this.setError("Что то пошло не так...");
      console.error('Error handling lobby:', error);
      throw error;
    }
  }

  updateLobby = (lobbyData) => {
    if (lobbyData.type) {
      this.lobby.type = lobbyData.type;
    }
    if (lobbyData.opponent) {
      this.lobby.opponent = lobbyData.opponent;
    }
    if (lobbyData.status) {
      this.lobby.status = lobbyData.status;
    }
  }

  // Проверка URL на наличие invite параметра
  checkInviteUrl = () => {
    try {
      // Получаем URL из Telegram WebApp
      let params = window.Telegram?.WebApp?.initDataUnsafe?.start_param;
      params = atob(params)
      params = JSON.parse(params)
      if (params.type == "friend") {
        this.handleInviteHash(params.hash);
      } if (params.type == "duel") {
        this.handleLobbyHash(params.hash);
      }
    } catch (error) {
      console.error('Error checking invite URL:', error);
    }
  }

  // Create invoice for payment
  createInvoice = async (itemId, price, quantity) => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.PAYMENT_CREATE_INVOICE}?quantity=${quantity}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json',
        },
      });
      this.clearError();
      return response.data;
    } catch (error) {
      this.setError("Что то пошло не так...");
      console.error('Failed to create invoice:', error);
      throw error;
    }
  };

  async submitGuess() {
    const guess = this.currentGame.currentGuess;
    if (guess.length !== 5) return;

    const isValid = await this.isValidWord(guess);
    if (!isValid) {
      this.currentGame.invalidGuess = true;
      soundService.playSound('defeat'); // Добавляем звук для неверного слова
      setTimeout(() => {
        this.currentGame.invalidGuess = false;
      }, 2000);
      return;
    }

    const targetWord = this.currentGame.word;
    const remainingLetters = [...targetWord];
    const letterStates = { ...this.currentGame.letterStates };
    const guessLetterStates = Array(5).fill('absent');

    // Сначала проверяем точные совпадения
    for (let i = 0; i < guess.length; i++) {
      const letter = guess[i];
      if (letter === targetWord[i]) {
        guessLetterStates[i] = 'correct';
        letterStates[letter] = 'correct';
        remainingLetters[i] = null;
        this.currentGame.score += GAME_SCORE_CONSTANTS.GREEN_LETTER_POINTS;
      }
    }

    // Затем проверяем буквы, которые есть в слове, но на других позициях
    for (let i = 0; i < guess.length; i++) {
      const letter = guess[i];
      if (guessLetterStates[i] !== 'correct') {
        const indexInRemaining = remainingLetters.indexOf(letter);
        if (indexInRemaining !== -1) {
          guessLetterStates[i] = 'present';
          letterStates[letter] = letterStates[letter] === 'correct' ? 'correct' : 'present';
          remainingLetters[indexInRemaining] = null;
          this.currentGame.score += GAME_SCORE_CONSTANTS.YELLOW_LETTER_POINTS;
        } else {
          letterStates[letter] = letterStates[letter] || 'absent';
        }
      }
    }

    this.currentGame.letterStates = letterStates;
    this.currentGame.guesses.push({
      word: guess,
      letterStates: guessLetterStates
    });

    this.currentGame.currentRow++;
    this.currentGame.currentGuess = '';
    this.currentGame.moves++;

    // Обновляем очки
    if (guess === targetWord) {
      this.currentGame.gameOver = true;
      const bonus = GAME_SCORE_CONSTANTS.WORD_GUESS_BONUS;
      this.currentGame.score += bonus;
      this.currentGame.lastScoreChange = { amount: bonus, timestamp: Date.now() };

      // Бонус за неиспользованные попытки
      const unusedAttemptsBonus = (6 - this.currentGame.currentRow) * GAME_SCORE_CONSTANTS.UNUSED_ATTEMPTS_BONUS;
      this.currentGame.score += unusedAttemptsBonus;

      soundService.playSound('victory'); // Добавляем звук победы
      this.sendWinData(guess);
    } else {
      soundService.playClick(); // Добавляем звук для правильного формата слова
      // Проверяем на поражение (все попытки использованы)
      if (this.currentGame.currentRow >= 6) { 
        this.currentGame.gameOver = true;
        soundService.playSound('defeat');
      }
    }

    this.saveGameState();
  };

  async isValidWord(word) {
    try {
      const response = await axios.post(`${API_BASE_URL}${API_ENDPOINTS.MOVE}`, {
        move: word
      }, {
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      });
      return response.data.ok === true;
    } catch (error) {
      console.error('Ошибка при проверке слова:', error);
      return false;
    }
  }

  updateCurrentGuess = (letter) => {
    if (this.currentGame.currentGuess.length < 5 && !this.currentGame.gameOver) {
      this.currentGame.currentGuess += letter;
      soundService.playClick(); // Добавляем звук при вводе буквы
      this.saveGameState();
    }
  };

  deleteLastLetter = () => {
    if (this.currentGame.currentGuess.length > 0) {
      this.currentGame.currentGuess = this.currentGame.currentGuess.slice(0, -1);
      soundService.playClick(); // Добавляем звук при стирании буквы
      this.saveGameState();
    }
  };

  generateRandomWord() {
    const words = ['голос', 'волос', 'стопа', 'червь', 'глина'];
    return words[Math.floor(Math.random() * words.length)];
  }

  fetchFriends = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.FRIENDS}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.friends = response.data || [];
      }
    } catch (error) {
      this.setError("Не удалось загрузить список друзей");
    }
  };

  fetchLeaderboard = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.LEADERBOARD}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.leaderboard = response.data.leaderboard || [];
        this.myPosition = response.data.position || "???";
      }
    } catch (error) {
      this.setError("Не удалось загрузить таблицу лидеров");
    }
  };

  fetchProfile = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}${API_ENDPOINTS.PROFILE}`, {
        headers: {
          'Authorization': `Bearer ${this.token}`,
          'Content-Type': 'application/json'
        }
      });
      if (response.status === 200) {
        this.updateProfile(response.data.profile);
      }
    } catch (error) {
      this.setError("Не удалось загрузить профиль");
    }
  };

  async handleNewProfile() {
    this.isNewProfile = true;
  }

  setProfileTutorialShown() {
    this.isNewProfile = false;
  }

  updateTickets(total, income) {
    this.tickets = total;
  }

  showAlert(content) {
    this.alert = {
      show: true,
      content
    };
  }

  hideAlert() {
    this.alert.show = false;
  }
}

const gameStore = new GameStore();
export default gameStore;
