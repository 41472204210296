class SoundService {
    constructor() {
        // Load sound state from localStorage or default to true
        this.isSoundEnabled = localStorage.getItem('soundEnabled') === 'false' ? false : true;
        this.sounds = {
            click: new Audio('/sounds/click.mp3'),
            victory: new Audio('/sounds/victory.mp3'),
            defeat: new Audio('/sounds/defeat.mp3'),
            newgame: new Audio('/sounds/newgame.mp3')
        };

        // Предзагрузка звуков
        Object.values(this.sounds).forEach(sound => {
            sound.load();
            // Включаем автовоспроизведение на мобильных устройствах
            sound.setAttribute('playsinline', '');
            sound.setAttribute('webkit-playsinline', '');
        });
    }

    toggleSound() {
        this.isSoundEnabled = !this.isSoundEnabled;
        // Save sound state to localStorage
        localStorage.setItem('soundEnabled', this.isSoundEnabled.toString());
        return this.isSoundEnabled;
    }

    playSound(soundName) {
        if (this.isSoundEnabled && this.sounds[soundName]) {
            const sound = this.sounds[soundName];
            const playPromise = sound.play();
            if (playPromise !== undefined) {
                playPromise.then(() => {
                    sound.currentTime = 0;
                }).catch(error => {
                    setTimeout(() => {
                        sound.currentTime = 0;
                        sound.play().catch(() => {});
                    }, 100);
                });
            }
        }
    }

    playClick() {
        this.playSound('click');
    }

    // Используем click для перемещения карт
    playCardMove() {
        this.playSound('click');
    }

    // Используем click для открытия карт
    playCardFlip() {
        this.playSound('click');
    }

    // Используем defeat для неправильного хода
    playWrongMove() {
        this.playSound('defeat');
    }

    playVictory() {
        this.playSound('victory');
    }

    playDefeat() {
        this.playSound('defeat');
    }

    playNewGame() {
        this.playSound('newgame');
    }
}

const soundService = new SoundService();
export default soundService;
