// WordGamePage.jsx
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import WordGrid from "../components/WordGrid";
import Header from "../components/Header";
import VictoryModal from "../components/VictoryModal";
import DefeatModal from "../components/DefeatModal";
import Keyboard from "../components/Keyboard";
import HowToPlayModal from '../components/HowToPlayModal';

const WordGamePage = observer(() => {
  const { invalidGuess } = gameStore.currentGame;
  const [showVictoryModal, setShowVictoryModal] = useState(false);
  const [showDefeatModal, setShowDefeatModal] = useState(false);
  const [shakeRow, setShakeRow] = useState(false);
  const [isHowToPlayOpen, setIsHowToPlayOpen] = useState(false);

  useEffect(() => {
    if (invalidGuess) {
      setShakeRow(true);
      const timer = setTimeout(() => {
        setShakeRow(false);
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [invalidGuess]);

  useEffect(() => {
    if (gameStore.isNewProfile) {
      setIsHowToPlayOpen(true);
      gameStore.setProfileTutorialShown();
    }
  }, [gameStore.isNewProfile]);

  return (
    <div className="fixed inset-0 flex flex-col text-[#F6F3FC] h-[100dvh]">
      <Header />
      <div className="flex-1 flex flex-col mt-10 gap-2">
        <div className="flex-1 flex flex-col justify-center">
          <WordGrid onTutorialOpen={() => setIsHowToPlayOpen(true)} />
        </div>
        <div className="pb-1">
          <Keyboard />
        </div>
      </div>
      <VictoryModal isOpen={showVictoryModal} onClose={() => setShowVictoryModal(false)} />
      <DefeatModal isOpen={showDefeatModal} onClose={() => setShowDefeatModal(false)} />
      <HowToPlayModal isOpen={isHowToPlayOpen} onClose={() => setIsHowToPlayOpen(false)} />
    </div>
  );
});

export default WordGamePage;
